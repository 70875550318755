

@keyframes moveup {
    0%   {opacity: 0; transform: translateY(12px);}
    100%  {opacity: 1; transform: translateY(0px);}
}

@keyframes movedown {
    0%   {opacity: 1; transform: translateY(0px);}
    100%  {opacity: 0; transform: translateY(-12px);}
}

.odometerStart{
    display: inline-block;
    animation-name: modeup 0.25s;
    -webkit-animation: moveup 0.25s;
    animation-fill-mode: forwards;
}
.odometerEnd{
    display: inline-block;
    animation-name: movedown 0.25s;
    -webkit-animation: movedown 0.25s;
    animation-fill-mode: forwards;
}

.odometer-block{
    display: inline-block;
    justify-content: center;
    align-items: center;
}

.odometer-block{
	display: flex;
	justify-content: space-between;
}
.odometer-block > span{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 10%;
	background: #205e86;
	color: #FFF;
	padding: 1.5em;
	border-radius: 10px;
	font-size: 13px;
	text-transform: uppercase;
}
.odometer-block > span > span{
	padding: .5em 0em;
}
